import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    List,
    Badge,
    Input,
    Tag,
    Form,
    Button,
    InputNumber,
    DatePicker,
    Radio,
    Checkbox,
    Row,
    Col,
    Select,
    Descriptions,
    Space,
    message,
} from 'antd';
import _ from "lodash";
import moment from 'moment'
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail, getProjectsByType, updateParentProject } from '../../util/APIUtils';
import { capitalize, formatCurrency } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import SingleSelect from '../../common/SingleSelect';
import AmountSummary from './AmountSummary';
import shortid from 'shortid';
import LineSelectionSingle from '../../common/LineSelectionSingle';
import LineSelectionLookupSingleWithAdd from '../../common/LineSelectionLookupSingleWithAdd';
import BreedingMethodLookup from '../../common/BreedingMethodLookup';

const { RangePicker } = DatePicker;

function PlanEz(props) {
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const workFlowState = props.workFlowState;

    const [tLineSelections, setTLineSelections] = useState([])
    const [state, setState] = useState(props.projectDetail);
    const editable = props.isPlanEditable
    const [projectOptions, setProjectOptions] = useState([]);
    const cropId = props.cropId;

    let breedingMethods = state.breedingMethodList


    const getCheckedStatus = () => {
        const tArray = [];
        if (state.plannedSeeds) {
            tArray.push('A');
        }
        if (state.plTaetSelection) {
            tArray.push('P');
        }
        return tArray;
    }
    const screenState = {
        "year": state.year.toString(),
        "tab": '1'
    }

    const setEditingLocal = (val) => {
        console.log(val)
        setEditing(val);
        props.setEditingMaster(val);
        if (!val) {
            props.reloadProject(screenState)
        }

    }

    const handleChange = e => {
        console.log(e)
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        console.log(name)
        console.log(value)

        if (name === 'lineSelections') {
            value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : state.lineSelections;
        } else if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        } else if (name === "plannedSeeds") {
            value = value.target.checked ? 1 : 0;
        }
        else if (name === "plTaetSelection") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateParentProjectNumber = () => {
        console.log("Updating Parent")
        let promise;
        promise = updateParentProject(state.projectID, state.parentProjectNumber);

        if (!promise) {
            return;
        }
        promise.then(() => {
            setEditing(false);

        }).catch(error => {
            console.log(error);
            setLoading(false)
        });
    }

    const getEzProjects = React.useCallback(async () => {
        let promise;
        promise = getProjectsByType("EZ");

        if (!promise) {
            return;
        }

        promise.then(response => {
            setProjectOptions(response.map(item => ({ "type": "project", "value": item.projectNo + " : " + item.description, "id": item.projectNo })))

        }).catch(error => {
            console.log(error)
        });

    }, [])

    useEffect(() => {
        getEzProjects();
    }, [getEzProjects]);

    const mapObjToPlan = (values, type) => {
        const processDateValue = (dateValue, type) => {
            // Check if the dateValue is a valid number or string to be processed
            if (typeof dateValue == 'number' || typeof dateValue == 'string') {
                if (type == 'obj') {
                    // If the type is 'obj', return a moment object
                    return moment(dateValue, 'YYYY');
                } else if (type == 'form') {
                    // If the type is 'form', return the year as a number
                    return parseInt(dateValue, 10);
                }
            } else if (moment.isMoment(dateValue) && dateValue.isValid()) {
                // If dateValue is a valid moment object
                if (type == 'obj') {
                    return dateValue;
                } else if (type == 'form') {
                    return dateValue.year();
                }
            }
        
            return null;
        };

        const {
            annualRegistration, checkBSA, nextPlace, testPatternCreation, bsaextension,
            cultivationScope, cultivationScopeUnit, increase, descBreedingMethod, priority
        } = values;

        let planObj = {
            breedingMethodList: type === "form" ? state.breedingMethodList : state.breedingMethodList.map(elem => elem.id),
            parentProjectNumber: state.parentProjectNumber,
            lastLineDescription: state.lastLineDescription,
            annualRegistration: processDateValue(annualRegistration, type), 
            bsaextension: bsaextension,
            plannedSeeds: state.plannedSeeds,
            plTaetSelection: state.plTaetSelection,
            plannedActivity: type === "form" ? null : state.plannedActivity,
            checkBSA:  processDateValue(checkBSA, type),
            cultivationScope: cultivationScope,
            cultivationScopeUnit: cultivationScopeUnit,
            testPatternCreation:  processDateValue(testPatternCreation, type),
            nextPlace: processDateValue(nextPlace, type),
            increase: increase,
            descBreedingMethod: descBreedingMethod,
            priority: priority,
            id: state.id,
            projectID: state.projectID,
            projectNumber: state.projectNumber,
            ks_plan: state.ks_plan
        };

        console.log(type)
        console.log(planObj);

        return planObj;
    };


    const onFinish = (values) => {
        console.log("submitting form")
        console.log(values)

        let promise;
        console.log(mapObjToPlan(values, "form"))
        promise = saveProjectDetail(mapObjToPlan(values, "form"));

        if (!promise) {
            return;
        }

        promise.then((response) => {
            console.log(response)

            if (state.parentProjectNumber) {
                updateParentProjectNumber();
            }
            setEditing(false);
            message.success("Projektplan aktualisiert")
            props.reloadProject(screenState);


        }).catch(error => {
            console.log(error)
            message.error("project update failed")
        });
    };

    const [form] = Form.useForm();

    form.setFieldsValue({
        plannedSeeds: state.plannedSeeds,
        plTaetSelection: state.plTaetSelection

    });


    // const allBreedingMethods = props.breedingMethods.filter(function (el) {
    //     return el.projectType == props.projectInfo.projectType
    // });


    // let breedingMethods = state.breedingMethodList.map(a => a.name);
    // let existingBreedingMethods = state.breedingMethodList.map(a => a.id);

    // console.log(breedingMethods)


    const initVals = mapObjToPlan(state, "obj");

    console.log("INIT VALS")
    console.log(initVals)



    return (
        <div>
            <Card bordered={true}
                extra=
                {editable ?
                    !editing ?
                        <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> :
                        <Space>
                            <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                            <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                        </Space> : ""

                }>
                <Form
                    // ref={this.formRef}
                    name="plan-x"
                    span={3}
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={initVals}
                >
                    <Descriptions
                        size="small"
                        bordered
                        style={{
                            marginBottom: 2
                        }}>
                        <Descriptions.Item label="Enstanden aus projekt" span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            {editing && editable ?
                                <Form.Item
                                    name="parentProjectNumber"
                                    hasFeedback
                                >
                                    <SingleSelect
                                        name={"parentProjectNumber"}
                                        setItems={handleChangeOther("parentProjectNumber")}
                                        existingItems={[state["parentProjectNumber"]]}
                                        itemList={projectOptions} />
                                </Form.Item>
                                : state["parentProjectNumber"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Letzte Sorten/Linienbezeichnung" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="lastLineDescription"
                                    hasFeedback
                                >

                                    <LineSelectionLookupSingleWithAdd
                                        cropId={cropId}
                                        setItem={handleChangeOther("lastLineDescription")}
                                        existingItem={state["lastLineDescription"]}
                                        projectInfo={props.projectInfo}
                                    />
                                    {/* <LineSelectionSingle
                                    cropId={cropId}
                                    setItem={handleChangeOther("lastLineDescription")}
                                    existingItem={state["lastLineDescription"]}
                                /> */}
                                </Form.Item>
                                : state["lastLineDescription"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Zulassungsjahr" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="annualRegistration"
                                    hasFeedback
                                >
                                    <DatePicker picker="year" />
                                </Form.Item>
                                : state["annualRegistration"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Zulassung soil verlängert werden" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="bsaextension"
                                    hasFeedback
                                >
                                    <Select valuePropName="option" style={{ width: '100px' }}>
                                        <Option value="ja">Ja</Option>
                                        <Option value="nein">Nein</Option>
                                    </Select>
                                </Form.Item>
                                : state["bsaextension"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Gepl Tätigkeit" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <div>

                                    <Checkbox.Group style={{ width: '100%' }} onChange={handleChangeOther("plannedActivity")} defaultValue={getCheckedStatus()} >
                                        <Row>
                                            <Form.Item
                                                name="plannedSeeds"
                                            >
                                                <Col span={24}>
                                                    <Checkbox name="plannedSeeds" value="A" onChange={handleChangeOther("plannedSeeds")}
                                                    >Anbau Auslesebestand</Checkbox>
                                                </Col>
                                            </Form.Item>
                                            <Form.Item
                                                name="plTaetSelection"
                                            >
                                                <Col span={24}>
                                                    <Checkbox name="plTaetSelection" value="P" onChange={handleChangeOther("plTaetSelection")}>
                                                        Pflege Samentrager/Samemernte</Checkbox>
                                                </Col>
                                            </Form.Item>
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                                : <Checkbox.Group style={{ width: '100%' }} defaultValue={getCheckedStatus()} disabled >
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="A"  >Anbau Auslesebestand</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="P"  >Pflege Samentrager/Samemernte</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>}
                        </Descriptions.Item>

                        {/* <Descriptions.Item span={3} label="Zulassung verlängert bis" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="plannedActivity"
                                    hasFeedback
                                >
                                    <Input name="plannedActivity" value={state["plannedActivity"]} onChange={handleChange} />
                                </Form.Item>
                                : state["plannedActivity"]}
                        </Descriptions.Item> */}

                        <Descriptions.Item span={3} label="Nächster prufanbau BSA" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="checkBSA"
                                    hasFeedback
                                >
                                    <DatePicker picker="year" />
                                </Form.Item>
                                : state["checkBSA"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Anbaummfang" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?

                                <Space>
                                    <Form.Item
                                        name="cultivationScope"
                                        hasFeedback
                                    >
                                        <InputNumber onChange={handleChangeOther("cultivationScope")} />
                                    </Form.Item>
                                    <Form.Item
                                        name="cultivationScopeUnit">
                                        <Select
                                            valuePropName="option"
                                            // value={value.currency || currency}
                                            style={{ width: '100px' }}
                                            onChange={handleChangeOther("cultivationScopeUnit")}
                                            defaultValue={state["cultivationScopeUnit"]}
                                        >

                                            <Option value={"qm"}>qm</Option>
                                            <Option value={"ha"}>ha</Option>
                                            <Option value={"stück"}>stück</Option>
                                        </Select>
                                    </Form.Item>
                                </Space>

                                : state["cultivationScope"] + " " + state["cultivationScopeUnit"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Prüfmuster für BSA fertig" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="testPatternCreation"
                                    hasFeedback
                                >
                                    <DatePicker picker="year" />
                                </Form.Item>
                                : state["testPatternCreation"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="nächstes basissaatgut für SGV fertig" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="nextPlace"
                                    hasFeedback
                                >
                                    {/* <DatePicker picker="year" /> */}
                                </Form.Item>
                                : state["nextPlace"] == 0 ? "noch unklar" : state["nextPlace"]}
                        </Descriptions.Item>

                        <Descriptions.Item span={3} label="Erhaltungszucht innerh Vermehrung" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Form.Item
                                    name="increase"
                                    hasFeedback
                                >
                                    <Select valuePropName="option" style={{ width: 140 }}>
                                        <Option value="Ja">Ja</Option>
                                        <Option value="Nein">Nein</Option>
                                    </Select>
                                </Form.Item>
                                : state["increase"]}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?

                                <BreedingMethodLookup
                                    projectType={'EZ'}
                                    setItems={handleChangeOther("breedingMethodList")}
                                    existingItems={breedingMethods}
                                    setFormattedItems={handleChangeOther("breedingMethodList")}
                                />

                                // <MultiSelect
                                //     setItems={handleChangeOther("breedingMethodList")}
                                //     existingItems={existingBreedingMethods}
                                //     itemList={allBreedingMethods} />
                                :
                                <List
                                    size="small"
                                    // bordered
                                    dataSource={breedingMethods}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.name} /></List.Item>}
                                />
                            }
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.breeding_method_description")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="descBreedingMethod"

                                >
                                    <Input.TextArea name="descBreedingMethod" onChange={handleChange} />
                                </Form.Item>
                                : state.descBreedingMethod}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.priority")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?
                                <Form.Item
                                    name="priority"
                                >
                                    <Select
                                        // value={value.currency || currency}
                                        style={{ width: '100%', margin: '0 0px' }}
                                        name="priority"
                                        onChange={handleChangeOther("priority")}
                                    // onChange={onCurrencyChange}
                                    >
                                        <Option value={1}>Hoch</Option>
                                        <Option value={2}>Mittel</Option>
                                        <Option value={3}>Neidrig</Option>
                                    </Select>
                                </Form.Item>
                                : state.priority}
                        </Descriptions.Item>
                        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={"bei Kultursaat einreichen"} span={3}>
                            {!editing ? ( state.ks_plan == 1 ? ( "ja") : ( "nein")) : (
                              <Checkbox disabled={!editable} defaultChecked={state.ks_plan == 1 ? true : false} label={"bei Kultursaat einreichen"} onChange={handleChangeOther("ks_plan")}></Checkbox>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                    {editing && editable ? "" :
                        // <Badge.Ribbon text={state.ks_plan == 1 ? t("project_details.submitted_to_ks") : t("project_details.not_submitted")} color={state.ks_plan == 1 ? "green" : "volcano"}>
                        <AmountSummary key={shortid.generate()} projectDetailId={state.id} type={"plan"} workFlowState={workFlowState} reloadProject={() => props.reloadProject(screenState)} />
                        // </Badge.Ribbon>
                    }
                </Form>
            </Card>
        </div >
    );
}

export default PlanEz;
