import React, { Component } from 'react';
import {
    Card, notification,
    PageHeader, Form, Input,
    Select, Button, DatePicker, message
} from 'antd';
import { withRouter } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllProjectTypes, getAllCrops, getAllBreeders, createProject } from '../util/APIUtils';
import { withTranslation } from "react-i18next";
import { POLL_LIST_SIZE } from '../constants';
import './Projects.css';
import moment from 'moment';
import SortenSelect from '../common/SortenSelect';

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

class NewProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectTypes: [],
            projectType: null,
            sortenName: null,
            breeders: [],
            crops: [],
            isLoading: false,
            currentUser: props.currentUser
        };
    }

    handleBack = () => {
        this.props.history.goBack();
    };

    formRef = React.createRef();

    onFinish = (values) => {
        const { type, description, sorten, years, crop } = values;
        let finalDescription = description;

        if (type === "EZ") {
            finalDescription = this.state.sortenName;
        }

        if (type === "EZ" && !finalDescription) {
            message.error("Für EZ-Projekte ist Sorte erforderlich");
            return;
        }

        const projObj = {
            projectType: type,
            description: finalDescription,
            startYear: years[0].year(),
            status: "Active",
            endYear: years[1].year(),
            breederId: this.state.currentUser.breederInfo.id,
            cropId: crop,
            lastLineDescription: description,
            approvalEnd: null,
            archived: 0
        };

        console.log(projObj);

        createProject(projObj)
            .then(response => {
                console.log(response);
                notification.success({
                    message: 'Kultursaat',
                    description: "Project created!"
                });
                this.props.history.push(`/project/detail/${response}`);
            }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login again.');
                } else {
                    notification.error({
                        message: 'Kultursaat',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });
    };

    onReset = () => {
        this.formRef.current.resetFields();
        this.setState({ projectType: null, sortenName: null });
    };

    loadBreeders(page = 0, size = POLL_LIST_SIZE) {
        const promise = getAllBreeders(page, size);
        if (!promise) return;

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                breeders: response,
                isLoading: false
            });
        }).catch(error => {
            console.error("Error loading breeders:", error);
            this.setState({ isLoading: false });
        });
    }

    loadCrops() {
        const promise = getAllCrops();
        if (!promise) return;

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                crops: response,
                isLoading: false
            });
        }).catch(error => {
            console.error("Error loading crops:", error);
            this.setState({ isLoading: false });
        });
    }

    loadProjectTypes() {
        const promise = getAllProjectTypes();
        if (!promise) return;

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                projectTypes: response,
                isLoading: false
            });
        }).catch(error => {
            console.error("Error loading project types:", error);
            this.setState({ isLoading: false });
        });
    }

    componentDidMount() {
        this.loadProjectTypes();
        this.loadBreeders();
        this.loadCrops();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            // Reset State
            this.setState({
                projectTypes: [],
                breeders: [],
                crops: [],
                isLoading: false
            });

            this.loadProjectTypes();
            this.loadBreeders();
            this.loadCrops();
        }
    }

    render() {
        const { t } = this.props;
        const { projectType, breeders, crops, currentUser } = this.state;

        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={this.handleBack}
                    title={t("projects.new_project")}
                    subTitle=""
                />
                <Card bordered={true} loading={this.state.isLoading}>
                    <Form
                        {...layout}
                        ref={this.formRef}
                        name="control-ref"
                        onFinish={this.onFinish}
                        onValuesChange={(changedValues, allValues) => {
                            if (changedValues.type) {
                                this.setState({ projectType: changedValues.type });
                            }
                        }}
                    >
                        {/* Project Type Selection */}
                        <Form.Item
                            name="type"
                            label={t("projects.project_type")}
                            rules={[{ required: true, message: "Bitte wählen Sie einen Projekttyp aus!" }]}
                        >
                            <Select placeholder={"Typ"}>
                                <Option label="NZ: Neuzucht" value="NZ" key="NZ">
                                    NZ: Neuzucht
                                </Option>
                                <Option label="EZ: Erhaltungszucht" value="EZ" key="EZ">
                                    EZ: Erhaltungszucht
                                </Option>
                                <Option label="VA: Versuchsanbau" value="VA" key="VA">
                                    VA: Versuchsanbau
                                </Option>
                                <Option label="GL: Grundlagen" value="GL" key="GL">
                                    GL: Grundlagen
                                </Option>

                                <OptGroup label="Sortenarchive">
                                    <Option label="SA-Si: Sichtung" value="SA-Si" key="SA-Si">
                                        SA-Si: Sichtung
                                    </Option>
                                    <Option label="SA-SB: Samenbau" value="SA-SB" key="SA-SB">
                                        SA-SB: Samenbau
                                    </Option>
                                </OptGroup>
                            </Select>
                        </Form.Item>

                        {/* Conditional Rendering Based on Project Type */}
                        {projectType !== "EZ" ? (
                            <Form.Item
                                name="description"
                               label={"Projekttitel"}
                                rules={[{ required: true, message: "Bitte Projekttitel angeben" }]}
                            >
                                <TextArea rows={2} />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="sorten"
                                label={"Sorten"}
                                rules={[{ required: true, message: "Bitte Sorte angeben" }]}
                            >
                                <SortenSelect setItem={(designation) => this.setState({ sortenName: designation })} />
                            </Form.Item>
                        )}

                        {/* Year Range Picker */}
                        <Form.Item
                            name="years"
                            label={"Projektzeitraum"}
                            rules={[{ required: true, message: `Bitte Projektzeitraum angeben` }]}
                        >
                            <RangePicker
                                picker="year"
                                style={{ width: '100%' }}
                                disabledDate={(current) => {
                                    // Disable dates before last year
                                    return current && current < moment().subtract(1, 'years').endOf('year');
                                }}
                            />
                        </Form.Item>

                        {/* Breeder Selection */}
                        <Form.Item
                            name="breeder"
                            label={t("general.breeder")}
                            initialValue={currentUser.breederInfo.id}
                            rules={[{ required: true, message: `Bitte wählen Sie einen ${t("general.breeder")} aus!` }]}
                        >
                            <Select disabled>
                                {breeders.map((fbb) => (
                                    <Option key={fbb.id} value={fbb.id}>
                                        {fbb.firstName} {fbb.lastName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Crop Selection */}
                        <Form.Item
                            name="crop"
                            label={t("general.crop")}
                            rules={[{ required: true, message: `Bitte Kulturart angeben` }]}
                        >
                            <Select placeholder={t("general.crop")}>
                                {crops.map((fbb) => (
                                    <Option key={fbb.id} value={fbb.id}>
                                        {fbb.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Submit and Reset Buttons */}
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                {t("general.submit")}
                            </Button>
                            <Button htmlType="button" onClick={this.onReset} style={{ marginLeft: '8px' }}>
                                {t("general.reset")}
                            </Button>
                        </Form.Item>
                    </Form>

                </Card>
            </div>
        );
    }
}

export default withTranslation()(withRouter(NewProject));
