import React, { Component } from 'react';
import { Table, Layout, Button, Col, PageHeader, Card, Drawer, Select, Switch, Space, Badge, Avatar, Typography, Tooltip, Popover } from 'antd';
import { AntDesignOutlined } from '@ant-design/icons';
const { Header, Content, Footer, Sider } = Layout;
import { Link } from 'react-router-dom';
import { getAllProjects, getAllProjectsByUser, getFilteredProjects } from '../util/APIUtils';
import { POLL_LIST_SIZE } from '../constants';
import { withTranslation } from "react-i18next";
import Search from '../breeders/search/Search'
import { filterPlainArray, capitalize, getColorToneForProjectStatus } from '../util/Helpers';
import './Projects.css';
import ProjectFilter from './filters/ProjectFilter';
import { observer, inject } from 'mobx-react';
import { v4 as uuid } from "uuid";
import { getSystemStatus } from '../util/ProjectStatusHelper';
import ProjectStatusComponent from './ProjectStatusComponent';
const { Text } = Typography;


const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
@inject('projectsStore')
@observer
class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            projects: [],
            projectsFull: [],
            page: 0,
            size: 50,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            visible: false,
            userProjectsCount: 0,
            allProjectsCount: 0,
            selectVal: false,
            prjType: 'user',
            projectFilters: {}
        };
    }

    

    reloadProjects = () => {
        this.props.projectsStore.setProjectsAllLoaded(false);
        this.props.projectsStore.setProjectsAll([]);
        this.loadProjectList("filter");
    }

    loadProjectList() {
        let promise;

        if (this.props.projectsStore.projectsAllLoaded) {
            console.log("Store not empty")
            return;
        }

        console.log("Loading filtered projects")
        this.setState({ isLoading: true })
        promise = getFilteredProjects(this.props.projectsStore.filters);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            console.log("Setting filtered projects")
            this.props.projectsStore.setProjectsAll(response)
            this.props.projectsStore.setProjectsAllLoaded(true)
            this.setState({
                projects: response,
                isLoading: false,
            })

        }).catch(error => {
            console.log(error);
            this.props.projectsStore.setProjectsAllLoaded(false)
            this.setState({ isLoading: false })
        });

    }

    handleTableChange = (pagination, filters, sorter) => {
        console.log('table changed')
        this.setState({
            page: pagination
        })
        this.props.projectsStore.setPagination(pagination);
    };

    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.loadProjectList();
            this.reloadProjects();
        }
    }

    componentDidUpdate(nextProps) {
        // if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
        //     // Reset State
        //     this.setState({
        //         projects: [],
        //         // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
        //         isLoading: false
        //     });

        //     this.loadProjectList(this.state.prjType);
        // }

    }

    getTooltipTextForColor = (color) => {
        switch (color) {
            case "success":
                return "Projekt bewilligt";
            case "warning":
                return "Projekt wurde zurückgestellt";
            case "danger":
                return "Projekt wurde abgelehnt";
            default:
                return "Kein Projektstatus";
        }
    }

    onSelect = (val) => {
        let linkText = `/project/detail/${val}`
        this.props.history.push(linkText);
    }

    handleSelectChange(value) {
        this.setState()
    }


    getFilterData(arr, field, fieldId) {
        let filters = []
        filters = [...new Set(arr.map(a => { return { 'text': a[field], 'value': a[field] } }))];
        return filters.filter((v, i, a) => a.findIndex(t => (t.value === v.value && t.text !== null)) === i)
    }

    setEditing = (val) => {
        this.loadProjectList();
        this.setState(prevState => ({
            prjType: !val ? "user" : "all",
            userProjects: !prevState.userProjects,
            selectVal: !prevState.selectVal
        }));
    }

    getStatusColor = (status) => {
        if (status === true) return "#a7c957"; // Green for true
        if (status === false) return "#ff928b"; // Red for false
        return "#d9d9d9"; // Grey for null or empty
    }

    getColorForNumber = (record) => {
        let col = "";
              if(record.year == this.state.currentUser.projectYear.projectYear){
                switch (record.status) {
                    case "ok":
                        col = "success";
                        break;
                    case "zurückgestellt":
                        col = "warning"
                        break;
                    case "abgelehnt":
                        col = "danger"
                        break
                    default:
                        break;
                }
            }
        return col;
    }

    getColorForText = (record) => {
        if (record.reportEditable && record.reportFinished) {
            return "success";
        } else {
            return "";
        }
    }
    getStatusColorActive = (currentYearActive, lastYearActive) => {
        let baseStyle = {
            color: "#fff", // Default text color
            border: "0px solid transparent", // Default no border
        };

        if (currentYearActive === true) {
            // Green for true
            return { ...baseStyle, backgroundColor: "#a7c957" };
        }
        else {
            // Grey for null or empty, with special handling for 'A' legend if not active
            if (lastYearActive) {
                // If 'A' legend and not active, show green outline with transparent background
                return { ...baseStyle, color: "#a7c957", backgroundColor: "transparent", border: "1px solid #a7c957" };
            }
            // Grey background for null/empty and active
            return { ...baseStyle, backgroundColor: "#d9d9d9" };
        }
    }

    render() {
        const { t } = this.props;
        const projects = this.props.projectsStore.projectsAll;
        // const projects = this.state.projects;
        // const systemStatus = getSystemStatus(this.state.currentUser)
        const columns = [
            {
                title: () => <b>{t("projects.project_number")}</b>,
                dataIndex: 'projectNumber',
                key: 'projectNumber',
                render: (text, record) => {
                    let linkText = `/project/detail/${record.projectId}`
                    let numberColor = this.getColorForNumber(record);
                    let tooltipText = this.getTooltipTextForColor(numberColor);
                    return (
                        <Tooltip title={tooltipText}>
                            <span><b>
                                <Link
                                    to={{
                                        pathname: linkText,
                                        state: {
                                            id: record.projectId,
                                        }
                                    }}> <Text type={numberColor}>{text}</Text></Link>
                            </b>
                            </span>
                        </Tooltip>
                    )
                },
                width: 130,
                sorter: (a, b) => {
                    if (a.projectNumber < b.projectNumber) {
                        return -1;
                    }
                    if (a.projectNumber > b.projectNumber) {
                        return 1;
                    }
                    return 0;
                },
                sortDirections: ['descend', 'ascend']
            },
            {
                title: () => <b>{t("general.description")}</b>,
                dataIndex: 'projectTitle',
                key: 'projectNumber',
                width: 700,
                sorter: (a, b) => {
                    if (a.projectTitle < b.projectTitle) {
                        return -1;
                    }
                    if (a.projectTitle > b.projectTitle) {
                        return 1;
                    }
                    return 0;
                },
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => {
                    let textColor = this.getColorForText(record)
                    return <Text type={textColor}>{text}</Text>
                }

            },
            {
                title: () => <b>Typ</b>,
                dataIndex: 'projectType',
                key: 'projectNumber',
                width: 120,
                // filters: this.getFilterData(projects, "projectType", "projectType"),
                sortDirections: ['descend', 'ascend'],
                render: (text) => {
                    let tooltipText;
                    switch (text) {
                        case 'NZ':
                            tooltipText = 'NZ:Neuzucht';
                            break;
                        case 'EZ':
                            tooltipText = 'EZ:Erhaltungszucht';
                            break;
                        case 'VA':
                            tooltipText = 'VA:Versuchsanbau';
                            break;
                        case 'GL':
                            tooltipText = 'GL:Grundlagen';
                            break;
                        default:
                            tooltipText = '';
                    }
                    return (
                        <Tooltip title={tooltipText}>
                            {text}
                        </Tooltip>
                    );
                },
            
            },
            {
                title: () => <b>{t("breeders.breeder_name")}</b>,
                dataIndex: 'breederName',
                key: 'projectNumber',
                width: 300,
                // filters: this.getFilterData(projects, "breederName", "breederName"),
            },
            {
                title: () => <b>{t("crops.crop_name")}</b>,
                dataIndex: 'cropName',
                key: 'projectNumber',
                width: 300,
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: () => <b>{t("general.status")}</b>,
                dataIndex: 'status',
                key: 'projectNumber',
                width: 250,
                // filters: this.getFilterData(projects, "status", "status"),

                render: (text, record) => (
                    <ProjectStatusComponent
                        currentUser={this.state.currentUser}
                        record={record}
                    />
                )




            }
        ];
        let guestUser = this.state.currentUser?.roles.find(x => x.name === 'ROLE_GUEST');
        const placeHolder = ` ${t("general.projects")}`
        // console.log(this.state.currentUser.projectYear.currentPhase == 1) 
        // console.log(this.state.currentUser.projectYear.accessOverride?.planWrite)
        return (
            this.props.isAuthenticated && <div>
                <PageHeader
                    className="site-page-header"
                    title={t("general.projects")}
                    subTitle={<Search dataList={projects}
                        dataCount={projects.length}
                        placeHolder={placeHolder}
                        onSelect={this.onSelect}
                        key={uuid()}
                    />}
                    extra={[
                        // systemStatus == 1 ? <Link to="/project/new"><Button key="3" type="primary" >{t("general.add")}</Button></Link>: ""]}
                        !guestUser && ([1, 3].includes(this.state.currentUser.projectYear.currentPhase) || this.state.currentUser.accessOverride?.planWrite) && <Link to="/project/new"><Button key="3" type="primary" >{t("general.add")}</Button></Link>]}
                />
                <Card bordered={true}
                    loading={this.state.isLoading}
                    // title={bList.length > 0 ?
                    // <Search dataList={bList}
                    //     dataCount={bList.length}
                    //     placeHolder={placeHolder}
                    //     onSelect={this.onSelect}
                    // /> : ""}
                    title={<ProjectFilter projectsStore={this.props.projectsStore} reloadProjects={this.reloadProjects} />}
                    extra={[
                        <Space size={'large'}>

                        </Space>]}
                >
                    <Table
                        size='small'
                        bordered
                        rowClassName={(record) => getColorToneForProjectStatus(record.status)}
                        columns={columns}
                        dataSource={projects}
                        loading={this.state.isLoading}
                        onChange={this.handleTableChange}
                        pagination={this.props.projectsStore.pagination ? this.props.projectsStore.pagination :
                            { defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['50', '100', '250'] }}
                    // filters={ProjectStoreObj.filters}
                    />

                </Card>
            </div>

        );
    }
}
export default withTranslation()(Projects);
