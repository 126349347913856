import React, { useState, useEffect } from 'react';
import { Table, Select, Tag, PageHeader } from 'antd';
import { getFgBudgetsForYear, getPdmForYear } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import { Link } from 'react-router-dom';
import FgBoardReviewModal from './FgBoardReviewModal';
import YearSelect from '../common/YearSelect';

const { Option } = Select;

const getStatusTag = (status) => {
    switch (status) {
        case 'ok':
            return <Tag color="#87d068" style={{ float: 'right', width: '100px' }} >OK</Tag>;
        case 'abgelehnt':
            return <Tag color="#f50" style={{ float: 'right', width: '100px' }} >Abgelehnt</Tag>;
        case 'zurückgestellt':
            return <Tag color="#ffc300" style={{ float: 'right', width: '100px' }}>Zurückgestellt</Tag>;
        default:
            return <Tag style={{ float: 'right', width: '100px' }} >Kein Status</Tag>;
    }
}


const subtotalRow = (data, breederName) => (
    <Table.Row style={{ fontWeight: 'bold' }}>
        <Table.Cell colSpan={3} align="right">
            Subtotal for {breederName}:
        </Table.Cell>
        <Table.Cell align="right">
            ${data.reduce((sum, row) => sum + row.amount, 0)}
        </Table.Cell>
    </Table.Row>
);


const ReviewFgList = (props) => {
    let year = props.currentUser?.projectYear.projectYear;
    console.log(props)
    const [fgBudgetList, setFBudgetList] = useState([]);
    const [selectedYear, setSelectedYear] = useState(year);

    let useMyData = getFgBudgetsForYear(selectedYear);

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }
    // TODO get year picker


    const columns = [
        {
            title: 'Breeder Name',
            dataIndex: 'breederName',
            key: 'breederName',
        },
        {
            title: 'Plan',
            dataIndex: 'activityPlan',
            key: 'activityPlan',
            render: (text, record) => {
                // Provide a default text if 'activityPlan' is null or undefined
                const displayText = text ? text : "Kein Titel";
                return (
                    <Link
                        to={`/fg_budget_detail/${record.cropId}/${record.breederId}`}
                        style={{ color: 'blue' }} // Inline style to make the link blue
                    >
                        <i>{displayText}</i>
                    </Link>
                );
            }
        },
        {
            title: 'Kulturart',
            dataIndex: 'cropName',
            key: 'cropName',
        },
        {
            title: 'Betrag',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (text) => <b>{formatCurrency(text)}</b>
        },
        {
            title: 'FG Status',
            dataIndex: 'statusFg',
            key: 'amountFG',
            render: (text) => getStatusTag(text)
        },
        {
            title: 'Betrag FG',
            dataIndex: 'fgOkAmount',
            key: 'fgOkAmount',
            align: 'right',
            render: (text) => <b>{formatCurrency(text)}</b>
        },
        {
            title: 'VS Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => getStatusTag(text)
        },
        {
            title: 'Auszahlbetrag',
            dataIndex: 'payoutOkAmount',
            key: 'payoutOkAmount',
            align: 'right',
            render: (text) => <b>{formatCurrency(text)}</b>
        },
        {
            title: 'Vorstand Review',
            dataIndex: 'status',
            key: 'status',

            render: (text, record) => {
                return <FgBoardReviewModal
                    fgBudgetId={record.id}
                    refetch={refetch}
                    comment={record.commentsBoard}
                    approvedAmount={record.payoutAmount}
                    approvalStatus={record.status}
                    projectNumber={record.projectNumber}
                    budgetTotal={record.budgetTotal}
                    amountFg={record.amountFg}
                    statusFg={record.statusFg}
                    commentsFg={record.commentsFg}
                />

                // {getStatusTag(text)}
            }
        },

    ];


    console.log(data)

    return (
        <div>
            <PageHeader title="FG Evaluation"
                className="site-page-header"
            />
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <YearSelect
                    setItem={setSelectedYear}
                    selectedItem={selectedYear}
                />
            </div>
            <Table
                size='small'
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data}
                pagination={false}
            >

            </Table>

        </div>
    );
}

export default ReviewFgList;
