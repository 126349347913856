import React, { Component } from 'react';
import { Table, Layout, Select, Button, Col, PageHeader, Card, Drawer, Typography, Collapse, Space } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
import { Link } from 'react-router-dom';
import { getYears, getFgBudgets, getFgBudgetsBreeder } from '../util/APIUtils';
import { isOfficeUser, formatCurrency } from '../util/Helpers';
import { withRouter } from 'react-router-dom';
import { POLL_LIST_SIZE } from '../constants';
import { withTranslation } from "react-i18next";
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
import _ from "lodash";

const { Text, Title } = Typography;
const { Panel } = Collapse;


class FachGroupBudgetYears extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fachgroupBudgets: [],
            fachgroupBudgetsSummary: [],
            page: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            visible: false,
            currentUser: props.currentUser,
            projectYears: []
        };
    }

    fetchYears() {
        this.setState({ isLoading: true });
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({ projectYears: response.reverse() })
            this.setState({ isLoading: false });

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }


    loadFgBudgetsSummary(year) {
        let promise;
        promise = getFgBudgetsBreeder(year);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                fachgroupBudgetsSummary: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadFgBudgets(year) {
        let promise;
        promise = getFgBudgets(year);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                fachgroupBudgets: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.fetchYears();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmprofiles: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.fetchYears();
        }
    }

    onSelect = (val) => {
        let linkText = `/farmprofile/detail/${val}`
        this.props.history.push(linkText);
    }

    handleYearInput = (val) => {
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.loadFgBudgetsSummary(val);
        this.loadFgBudgets(val);
    }

    render() {
        const { t } = this.props;

        let officeUser = isOfficeUser(this.state.currentUser)

        const expandedRowRender = row => {
            console.log(row)
            const columns = [
                {
                    title: "Plan",
                    dataIndex: 'activityPlan',
                    key: 'activityPlan',
                    // sortDirections: ['descend', 'ascend'],
                    
                    width: 500,
                   
                },

                {
                    title: "Kulturen",
                    dataIndex: 'cropName',
                    key: 'cropName',
                    sortDirections: ['descend', 'ascend'],
                    sorter: (a, b) => {
                        if (a.cropName < b.cropName) {
                            return -1;
                        }
                        if (a.cropName > b.cropName) {
                            return 1;
                        }
                        return 0;
                    },
                    width: 200
                },
                {
                    // title: t("general.address"),
                    title: "Budget",
                    dataIndex: 'amount',
                    align: "right",
                    key: 'amount',
                    width: 150,
                    sortDirections: ['descend', 'ascend'],
                    render(text, record) {
                        return {
                            children: <span>
                                <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>

                            </span>
                        };
                    }
                },
                {
                    // title: t("general.email"),
                    title: "FG Amount",
                    dataIndex: 'amountFg',
                    key: 'amountFg',
                    align: "right",
                    width: 150,
                    render(text, record) {
                        return {
                            children: <span>
                                <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>

                            </span>
                        };
                    }
                },
                {
                    // title: t("general.phone"),
                    title: "Payout",
                    dataIndex: 'payoutAmount',
                    key: 'payoutAmount',
                    align: "right",
                    width: 150,
                    render(text, record) {
                        return {
                            children: <span>
                                <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>

                            </span>
                        };
                    }
                },
                {
                    // title: t("general.phone"),
                    title: "Own share",
                    dataIndex: 'ownShare',
                    key: 'ownShare',
                    align: "right",
                    width: 150,
                    render(text, record) {
                        return {
                            children: <span>
                                <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>

                            </span>
                        };
                    }
                }
            ];
            const data = [];
            console.log(row.breederId)


            // var result = this.state.fachgroupBudgets.map( x => {
            //     x.key = x.breederId;
            //     return x
            //     })
            //     console.log(result)

            var result = this.state.fachgroupBudgets.filter(x => x.breederId === row.breederId);
            console.log(result)


            return <Table columns={columns} dataSource={result} pagination={false} size={"small"} showHeader={false} />;
        };

        const columns = [
            {
                title: "Züchter",
                dataIndex: 'itemName',
                key: 'itemName',

                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => {
                    if (a.breederName < b.breederName) {
                        return -1;
                    }
                    if (a.breederName > b.breederName) {
                        return 1;
                    }
                    return 0;
                },
                width: 685,
                render(text, record) {
                    return {
                        children: <span><b>
                            <Text>  {text}</Text>
                        </b>
                        </span>
                    };
                }
            },

            {
                // title: t("general.address"),
                title: "Budget",
                dataIndex: 'totalAmount',
                align: "right",
                key: 'totalAmount',
                width: 150,
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
            },
            {
                // title: t("general.email"),
                title: "FG Amount",
                dataIndex: 'totalAmountFg',
                key: 'totalAmountFg',
                align: "right",
                width: 150,
                render(text, record) {
                    return {
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
            },
            {
                // title: t("general.phone"),
                title: "Payout",
                dataIndex: 'totalPayoutAmount',
                key: 'totalPayoutAmount',
                align: "right",
                width: 150,
                render(text, record) {
                    return {
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
            },
            {
                // title: t("general.phone"),
                title: "Own share",
                dataIndex: 'totalOwnShare',
                key: 'totalOwnShare',
                align: "right",
                width: 150,
                render(text, record) {
                    return {
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
            }
        ];

        var result = this.state.fachgroupBudgetsSummary.map(x => {
            x.key = x.breederId.toString();
            return x
        })


        return (
            this.props.isAuthenticated && <div>
                <PageHeader
                    className="site-page-header"
                    title={"FG Budget"}
                    subTitle=""
                    extra={[
                        // officeUser ? <Link to="/farmprofile/new"><Button key="3" type="primary" >{t("general.add")}</Button></Link> : ""
                    ]
                    }

                />
                <Card bordered={true} loading={this.state.isLoading}

                >
                    <Space direction='vertical' size={'large'}>
                        <Select style={{ width: '140px' }} onChange={this.handleYearInput} defaultValue={this.state.year} placeholder={"Jahr"}>
                            {this.state.projectYears.map(item => (
                                <Select.Option key={item.projectYear} value={item.projectYear}>
                                    {item.projectYear}
                                </Select.Option>
                            ))}
                        </Select>

                        { }
                        <Table
                            columns={columns}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ['0'],
                            }}
                            dataSource={result}
                            size="small"
                            pagination={false}
                            loading={this.state.isLoading} />
                    </Space>
                </Card>
            </div>

        );
    }
}
export default withTranslation()(FachGroupBudgetYears);
