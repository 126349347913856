import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    Checkbox,
    List,
    Badge,
    Input,
    Space,
    Form,
    Button,
    InputNumber,
    Select,
    Descriptions,
    message,
    Modal,
    Empty
} from 'antd';
import _ from "lodash";
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail, getAllFarmProfiles, getProjectsByType, getLineSelections, getBreedingMethods } from '../../util/APIUtils';
import { capitalize, formatCurrency, getTextArtva } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import SingleSelect from '../../common/SingleSelect';
import AmountSummary from './AmountSummary';
import LineSelectionLookup from '../../common/LineSelectionLookup';
import Satz from '../../satz/Satz';
import LineSelectionLookupSingleWithAdd from '../../common/LineSelectionLookupSingleWithAdd';
import LineSelectionLookupWithAdd from '../../common/LineSelectionLookupWithAdd';
import PropTypes from 'prop-types';
import shortid from 'shortid';

const { Option } = Select;

function PlanVa(props) {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const editable = props.isPlanEditable;
    const [loading, setLoading] = useState(false);
    const workFlowState = props.workFlowState;
    const [allLineSelections, setAllLineSelections] = useState([]);
    const [state, setState] = useState({
        ...props.projectDetail,
        cultivars: props.projectDetail?.cultivars || [],
        referenceLines: props.projectDetail?.referenceLines || [],
    });
    const [cultVal, setCultVal] = useState(1);
    const [userProfiles, setUserProfiles] = useState([]);
    const [vaProjects, setVaProjects] = useState([]);
    const [options, setOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);

    const cropId = props.projectInfo.cropId;

    const screenState = {
        "year": state.year,
        "tab": '1'
    };

    const confirm = () => {
        Modal.confirm({
            width: 1200,
            content: (
                <Satz 
                    satzType="plan"
                    projectDetailId={props.projectDetail.id}
                    isPlanEditable={props.isPlanEditable}
                    isReportEditable={props.isReportEditable}
                />
            ),
            cancelText: 'Schließen',
        });
    };

    const fetchLines = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getLineSelections(cropId);
            if (response && Array.isArray(response)) {
                setAllLineSelections(response);
            } else {
                setAllLineSelections([]);
                console.warn("Expected an array for line selections, but received:", response);
            }
        } catch (error) {
            console.error("Error fetching line selections:", error);
            setAllLineSelections([]);
        } finally {
            setLoading(false);
        }
    }, [cropId]);

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCultChange = (val) => {
        setCultVal(val);
        setState(prevState => ({
            ...prevState,
            "artva": val,
            // Optionally reset related fields if necessary
        }));
    };

    const onFinish = async (values) => {
        const subsetObj = _.pick(state, [
            "id",
            "year",
            "artva",
            "parentNo",
            "projectNumber",
            "parentProjectTitle",
            "description",
            "plannedActivity",
            "cultivars",
            "referenceLines",
            "numberOfPlannedLines",
            "projectExtension",
            "generationStart",
            "generationEnd",
            "descBreedingMethod",
            "cultivationCompany",
            "cultivationCompanyId",
            "cultivationScope",
            "cultivationScopeUnit",
            "priority",
            "farmProfileId",
            "ks_plan"
        ]);

        try {
            await saveProjectDetail(subsetObj);
            message.success("Projektplan aktualisiert");
            props.reloadProject(screenState);
        } catch (error) {
            console.error("Error updating project plan:", error);
            message.error("Projektplan konnte nicht aktualisiert werden");
        }
    };

    const getFarmProfiles = useCallback(async () => {
        try {
            const response = await getAllFarmProfiles();
            if (response && Array.isArray(response)) {
                setUserProfiles(response);
                setOptions(response.map(item => ({ "type": "farm", "value": item.name, "id": item.id })));
            } else {
                setUserProfiles([]);
                setOptions([]);
                console.warn("Expected an array for farm profiles, but received:", response);
            }
        } catch (error) {
            console.error("Error fetching farm profiles:", error);
        }
    }, []);

    const getVaProjects = useCallback(async () => {
        try {
            const response = await getProjectsByType("VA");
            if (response && Array.isArray(response)) {
                setVaProjects(response);
                setProjectOptions(response.map(item => ({ "type": "project", "value": `${item.projectNo} : ${item.description}`, "id": item.projectNo })));
            } else {
                setVaProjects([]);
                setProjectOptions([]);
                console.warn("Expected an array for VA projects, but received:", response);
            }
        } catch (error) {
            console.error("Error fetching VA projects:", error);
        }
    }, []);

    useEffect(() => {
        getFarmProfiles();
        getVaProjects();
        fetchLines();
    }, [getFarmProfiles, getVaProjects, fetchLines]);

    const [form] = Form.useForm();

    // Safely map cultivars and referenceLines
    let cultivars = state.cultivars?.map(a => a.name) || [];
    let referenceLines = state.referenceLines?.map(a => a.name) || [];

    // Set form fields with default values to prevent uncontrolled components
    useEffect(() => {
        form.setFieldsValue({
            description: state.description || '',
            planned_activity: state.plannedActivity || '',
            youngest: state.generationStart || null,
            oldest: state.generationEnd || null,
            breeding_method_description: state.descBreedingMethod || '',
            priority: state.priority || 1,
            no_planned_lines: state.numberOfPlannedLines || 0,
            cultivation_scope: state.cultivationScope ? parseInt(state.cultivationScope) : 0,
            cultivation_scope_unit: state.cultivationScopeUnit || 'qm',
        });
    }, [form, state]);

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
    };

    return (
        <div>
            <Card bordered={true}
                extra={
                    editable ?
                        !editing ?
                            <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""
                }>
                <Form
                    name="plan-x"
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Descriptions
                        size="small"
                        bordered
                        style={{ marginBottom: 2 }}>
                        {/* What Cultivation */}
                        <Descriptions.Item span={3} label={t("project_details.what_cultivation")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Select defaultValue={state["artva"]} style={{ width: '100%' }} onChange={handleCultChange}>
                                    <Option value={1}>Interner Versuchsanbau nur auf eigenem Betrieb</Option>
                                    <Option value={2}>Übergeordnetes Versuchsanbau Projekt mit Tochterprojekten auf extern Standorten</Option>
                                    <Option value={3}>Tochterprojekt zu folgendem übergeordneten Projekt</Option>
                                </Select> :
                                getTextArtva(state["artva"])}
                        </Descriptions.Item>

                        {/* Parent Project (Conditional) */}
                        {state["artva"] === 3 &&
                            <Descriptions.Item span={3} label="Übergeordnetes Projekt" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                                {editing && editable ?
                                    <Form.Item
                                        name="parentNo"
                                        hasFeedback
                                    >
                                        <SingleSelect
                                            setItems={handleChangeOther("parentNo")}
                                            existingItems={[state.parentNo || "- : -"]}
                                            itemList={projectOptions} />
                                    </Form.Item>
                                    : `${state.parentNo} - ${state.parentProjectTitle}`}
                            </Descriptions.Item>
                        }

                        {/* Cultivation Company */}
                        <Descriptions.Item label="Anbaubetrieb" span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            {editing && editable ?
                                <Form.Item
                                    name="cultivationCompanyId"
                                    hasFeedback
                                >
                                    <SingleSelect
                                        setItems={handleChangeOther("cultivationCompanyId")}
                                        existingItems={[state.cultivationCompanyId]}
                                        itemList={options} />
                                </Form.Item>
                                : state.cultivationCompany}
                        </Descriptions.Item>

                        {/* Description */}
                        <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            {editing && editable ?
                                <Input.TextArea autoSize name="description" value={state["description"]} onChange={handleChange} />
                                : state["description"]}
                        </Descriptions.Item>

                        {/* Planned Activity */}
                        <Descriptions.Item span={3} label={t("project_details.planned_activity")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Input.TextArea autoSize name="plannedActivity" value={state["plannedActivity"]} onChange={handleChange} />
                                : state["plannedActivity"]}
                        </Descriptions.Item>

                        {/* Cultivars/Linien */}
                        <Descriptions.Item label="Zuchsorten/Linien" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="lineSelections"
                                    hasFeedback
                                >
                                    <LineSelectionLookupWithAdd
                                        cropId={cropId}
                                        setItems={handleChangeOther("cultivars")}
                                        existingItems={state.cultivars}
                                        setFormattedItems={handleChangeOther("cultivars")}
                                        projectInfo={props.projectInfo}
                                    />
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    dataSource={cultivars}
                                    renderItem={item => (
                                        <List.Item style={{ padding: 2 }}>
                                            <Badge color={'geekblue'} text={item} />
                                        </List.Item>
                                    )}
                                />}
                        </Descriptions.Item>

                        {/* Reference Lines */}
                        <Descriptions.Item label="Referenzsorten" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="referenceLines"
                                    hasFeedback
                                >
                                    <LineSelectionLookup
                                        cropId={cropId}
                                        setItems={handleChangeOther("referenceLines")}
                                        existingItems={state.referenceLines}
                                        setFormattedItems={handleChangeOther("referenceLines")}
                                    />
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    dataSource={referenceLines}
                                    renderItem={item => (
                                        <List.Item style={{ padding: 2 }}>
                                            <Badge color={'geekblue'} text={item} />
                                        </List.Item>
                                    )}
                                />}
                        </Descriptions.Item>

                        {/* Planned Lines */}
                        <Descriptions.Item label={t("project_details.planned_lines")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?
                                <InputNumber
                                    style={{ width: '100%' }}
                                    name="numberOfPlannedLines"
                                    value={state["numberOfPlannedLines"]}
                                    onChange={handleChangeOther("numberOfPlannedLines")}
                                />
                                : state.numberOfPlannedLines}
                        </Descriptions.Item>

                        {/* Cultivation Scope */}
                        <Descriptions.Item label={t("project_details.cultivation_scope")} labelStyle={{ fontWeight: 'bold' }} >
                            {editing && editable ?
                                <div>
                                    <InputNumber
                                        name="cultivationScope"
                                        value={state["cultivationScope"]}
                                        onChange={handleChangeOther("cultivationScope")}
                                    />
                                    <Select
                                        style={{ width: '25%', marginLeft: '10px' }}
                                        name="cultivationScopeUnit"
                                        defaultValue={state.cultivationScopeUnit}
                                        onChange={handleChangeOther("cultivationScopeUnit")}
                                    >
                                        <Option value={"qm"}>qm</Option>
                                        <Option value={"ha"}>ha</Option>
                                        <Option value={"stück"}>stück</Option>
                                    </Select>
                                </div>
                                : `${state.cultivationScope} ${state.cultivationScopeUnit}`}
                        </Descriptions.Item>

                        {/* Priority */}
                        <Descriptions.Item label={t("project_details.priority")} labelStyle={{ fontWeight: 'bold' }} span={4} >
                            {editing && editable ?
                                <Select
                                    style={{ width: '100%', margin: '0 0px' }}
                                    name="priority"
                                    defaultValue={state.priority}
                                    onChange={handleChangeOther("priority")}
                                >
                                    <Option value={1}>Hoch</Option>
                                    <Option value={2}>Mittel</Option>
                                    <Option value={3}>Neidrig</Option>
                                </Select>
                                : state.priority}
                        </Descriptions.Item>

                        {/* Submit to Kultursaat */}
                        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={"bei Kultursaat einreichen"} span={3}>
                            {!editing ? ( state.ks_plan == 1 ? ( "ja") : ( "nein")) : (
                              <Checkbox disabled={!editable} defaultChecked={state.ks_plan == 1 ? true : false} label={"bei Kultursaat einreichen"} onChange={handleChangeOther("ks_plan")}></Checkbox>
                            )}
                        </Descriptions.Item>

                    </Descriptions>

                    {/* Satz Button */}
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Button type='primary' onClick={confirm}>Sätze</Button> 
                    </div>

                    {/* Amount Summary */}
                    {editing && editable ? null :
                        <AmountSummary
                            key={shortid.generate()}
                            projectDetailId={state.id}
                            type={"plan"}
                            workFlowState={workFlowState}
                            reloadProject={() => props.reloadProject(screenState)}
                        />
                    }
                </Form>
            </Card>
        </div>
    );
}

// Define default props to prevent undefined values
PlanVa.defaultProps = {
    projectDetail: {
        cultivars: [],
        referenceLines: [],
        // Add other default fields as necessary
    },
    projectInfo: {
        cropId: 0,
        // Add other default fields as necessary
    },
};

// Define PropTypes for type checking
PlanVa.propTypes = {
    projectDetail: PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        artva: PropTypes.number,
        cultivars: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
        })),
        referenceLines: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
        })),
        // Define other expected fields
    }).isRequired,
    projectInfo: PropTypes.shape({
        cropId: PropTypes.number.isRequired,
        // Define other expected fields
    }).isRequired,
    isPlanEditable: PropTypes.bool.isRequired,
    isReportEditable: PropTypes.bool.isRequired,
    workFlowState: PropTypes.string.isRequired,
    reloadProject: PropTypes.func.isRequired,
    setEditingMaster: PropTypes.func.isRequired,
};

export default PlanVa;