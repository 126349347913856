import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    List,
    Badge,
    Input,
    Tag,
    Form,
    Button,
    InputNumber,
    DatePicker,
    Select,
    Descriptions,
    message,
    Checkbox,
    Space,
} from 'antd';
import _ from "lodash";
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail, getLineSelections } from '../../util/APIUtils';
import { capitalize, formatCurrency } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import AmountSummary from './AmountSummary';
import LineSelectionLookupWithAdd from '../../common/LineSelectionLookupWithAdd';
import PropTypes from 'prop-types';
import shortid from 'shortid';

const { Option } = Select;

function getColorToneForStatus(statusText) {
    let status;
    switch (statusText) {
        case "ok":
            status = "#55a630";
            break;
        case "zurückgestellt":
            status = "#ff6d00";
            break;
        case "abgelehnt":
            status = "#ff6b6b";
            break;
        default:
            status = "";
    }
    return status;
}

function processLineSelections(arr, cropId) {
    const bList = arr
        .filter(function (el) {
            return el.cropId == cropId;
        });
    return bList;
}

function onReset() {
    this.formRef.current.resetFields();
};

function Plan(props) {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const workFlowState = props.workFlowState;

    const [allLineSelections, setAllLineSelections] = useState([]);
    const [state, setState] = useState({
        ...props.projectDetail,
        lineSelections: props.projectDetail?.lineSelections || [],
        breedingMethodList: props.projectDetail?.breedingMethodList || [],
    });
    const editable = props.isPlanEditable;

    const projectType = "NZ"; // Assuming 'NZ' is the base type
    let screenState = {
        "year": state.year,
        "tab": '1'
    };
    const cropId = props.projectInfo.cropId;

    const fetchLines = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getLineSelections(cropId);
            if (response && Array.isArray(response)) {
                setAllLineSelections(response);
            } else {
                setAllLineSelections([]);
                console.warn("Expected an array for line selections, but received:", response);
            }
        } catch (error) {
            console.error("Error fetching line selections:", error);
            setAllLineSelections([]);
        } finally {
            setLoading(false);
        }
    }, [cropId]);

    useEffect(() => {
        fetchLines();
    }, [fetchLines]);

    const lineSelectionChildren = allLineSelections.map(element => (
        <Option label={element.name} value={element.id} key={element.id}>{element.name}</Option>
    ));

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        if (name === 'lineSelections' || name === 'breedingMethodList') {
            value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : [];
        } else if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onFinish = async (values) => {
        const subsetObj = _.pick(state, [
            "id",
            "projectNumber",
            "description",
            "plannedActivity",
            "lineSelections",
            "breedingMethodList",
            "numberOfPlannedLines",
            "projectExtension",
            "generationStart",
            "generationEnd",
            "descBreedingMethod",
            "cultivationScope",
            "cultivationScopeUnit",
            "priority",
            "ks_plan"
        ]);

        try {
            await saveProjectDetail(subsetObj);
            message.success("Projektplan aktualisiert");
            props.reloadProject(screenState);
        } catch (error) {
            console.error("Error updating project plan:", error);
            message.error("Aktualisierung des Projektplans fehlgeschlagen");
        }
    };

    const [form] = Form.useForm();

    const allBreedingMethods = props.breedingMethods.filter(function (el) {
        return el.projectType == props.projectInfo.projectType;
    });

    let breedingMethods = state.breedingMethodList?.map(a => a.name) || [];
    let existingBreedingMethods = state.breedingMethodList?.map(a => a.id) || [];

    // Safely map cultivars and referenceLines
    let lineSelections = state.lineSelections?.map(a => a.name) || [];

    // Set form fields with default values to prevent uncontrolled components
    useEffect(() => {
        form.setFieldsValue({
            description: state.description || '',
            planned_activity: state.plannedActivity || '',
            youngest: state.generationStart || null,
            oldest: state.generationEnd || null,
            breeding_method_description: state.descBreedingMethod || '',
            priority: state.priority || 1,
            no_planned_lines: state.numberOfPlannedLines || 0,
            cultivation_scope: state.cultivationScope ? parseInt(state.cultivationScope) : 0,
            cultivation_scope_unit: state.cultivationScopeUnit || 'qm',
            ks_plan: state.ks_plan || 0,
            lineSelections: state.lineSelections?.map(a => a.id) || []
        });
    }, [form, state]);

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
    };

    return (
        <div>
            <Card bordered={true} loading={loading}
                extra={
                    editable ?
                        !editing ?
                            <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""
                }>
                <Form
                    name="plan-x"
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Descriptions
                        size="small"
                        bordered
                        style={{ marginBottom: 2 }}>
                        {/* Description */}
                        <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} style={{ width: '400px' }}>
                            {editing && editable ?
                                <Input.TextArea name="description" value={state["description"]} onChange={handleChange} />
                                : state["description"]}
                        </Descriptions.Item>

                        {/* Planned Activity */}
                        <Descriptions.Item span={3} label={t("project_details.planned_activity")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Input.TextArea name="plannedActivity" value={state["plannedActivity"]} onChange={handleChange} />
                                : state["plannedActivity"]}
                        </Descriptions.Item>

                        {/* Line Selections */}
                        <Descriptions.Item label={t("project_details.starting_points")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="lineSelections"
                                    hasFeedback
                                >
                                    <LineSelectionLookupWithAdd
                                        cropId={cropId}
                                        setItems={handleChangeOther("lineSelections")}
                                        existingItems={state.lineSelections}
                                        setFormattedItems={() => console.log("val")}
                                        projectInfo={props.projectInfo}
                                    />
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    dataSource={lineSelections}
                                    renderItem={item => (
                                        <List.Item style={{ padding: 2 }}>
                                            <Badge color={'geekblue'} text={item} />
                                        </List.Item>
                                    )}
                                />
                            }
                        </Descriptions.Item>

                        {/* Planned Lines */}
                        <Descriptions.Item label={t("project_details.planned_lines")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?
                                <InputNumber
                                    style={{ width: '100%' }}
                                    name="numberOfPlannedLines"
                                    value={state["numberOfPlannedLines"]}
                                    onChange={handleChangeOther("numberOfPlannedLines")}
                                />
                                : state.numberOfPlannedLines}
                        </Descriptions.Item>

                        {/* Age of Lines Used */}
                        <Descriptions.Item label={t("project_details.age_of_lines_used")} labelStyle={{ fontWeight: 'bold' }} span={3}></Descriptions.Item>
                        <Descriptions.Item label={t("project_details.age_of_lines_used_youngest")} labelStyle={{ fontWeight: 'bold' }} style={{ width: '350px' }}>
                            {editing && editable ?
                                <InputNumber
                                    placeholder={t("project_details.age_of_lines_used_youngest")}
                                    name="generationStart"
                                    value={state["generationStart"]}
                                    onChange={handleChangeOther("generationStart")}
                                    style={{ width: '350px' }}
                                />
                                : state.generationStart}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.age_of_lines_used_oldest")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <InputNumber
                                    placeholder={t("project_details.age_of_lines_used_oldest")}
                                    name="generationEnd"
                                    value={state["generationEnd"]}
                                    onChange={handleChangeOther("generationEnd")}
                                />
                                : state.generationEnd}
                        </Descriptions.Item>

                        {/* Breeding Method */}
                        <Descriptions.Item label={t("project_details.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <MultiSelect
                                    setItems={handleChangeOther("breedingMethodList")}
                                    existingItems={existingBreedingMethods}
                                    itemList={allBreedingMethods}
                                />
                                : breedingMethods}
                        </Descriptions.Item>

                        {/* Breeding Method Description */}
                        <Descriptions.Item label={t("project_details.breeding_method_description")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Input.TextArea name="descBreedingMethod" value={state["descBreedingMethod"]} onChange={handleChange} />
                                : state.descBreedingMethod}
                        </Descriptions.Item>

                        {/* Cultivation Scope */}
                        <Descriptions.Item label={t("project_details.cultivation_scope")} labelStyle={{ fontWeight: 'bold' }} >
                            {editing && editable ?
                                <div>
                                    <InputNumber
                                        name="cultivationScope"
                                        value={state["cultivationScope"]}
                                        onChange={handleChangeOther("cultivationScope")}
                                    />
                                    <Select
                                        style={{ width: '25%', marginLeft: '10px' }}
                                        name="cultivationScopeUnit"
                                        defaultValue={state.cultivationScopeUnit}
                                        onChange={handleChangeOther("cultivationScopeUnit")}
                                    >
                                        <Option value={"qm"}>qm</Option>
                                        <Option value={"ha"}>ha</Option>
                                        <Option value={"stück"}>stück</Option>
                                    </Select>
                                </div>
                                : `${state.cultivationScope} ${state.cultivationScopeUnit}`}
                        </Descriptions.Item>

                        {/* Priority */}
                        <Descriptions.Item label={t("project_details.priority")} labelStyle={{ fontWeight: 'bold' }} span={4} >
                            {editing && editable ?
                                <Select
                                    style={{ width: '100%', margin: '0 0px' }}
                                    name="priority"
                                    defaultValue={state.priority}
                                    onChange={handleChangeOther("priority")}
                                >
                                    <Option value={1}>Hoch</Option>
                                    <Option value={2}>Mittel</Option>
                                    <Option value={3}>Neidrig</Option>
                                </Select>
                                : state.priority}
                        </Descriptions.Item>

                        {/* Reason for Extension */}
                        <Descriptions.Item label={t("project_details.reason_for_extension")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {state.planDeviation}
                        </Descriptions.Item>

                        {/* Submit to Kultursaat */}
                        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={"bei Kultursaat einreichen"} span={3}>
                            {!editing ? ( state.ks_plan == 1 ? ( "ja") : ( "nein")) : (
                              <Checkbox disabled={!editable} defaultChecked={state.ks_plan == 1 ? true : false} label={"bei Kultursaat einreichen"} onChange={handleChangeOther("ks_plan")}></Checkbox>
                            )}
                        </Descriptions.Item>
                    </Descriptions>

                    {/* Amount Summary */}
                    {editing && editable ? null :
                        <AmountSummary
                            key={shortid.generate()}
                            projectDetailId={state.id}
                            type={"plan"}
                            workFlowState={workFlowState}
                            reloadProject={() => props.reloadProject(screenState)}
                            currentUser={props.currentUser}
                        />
                    }
                </Form>
            </Card>
        </div>
    );
}

// Define default props to prevent undefined values
Plan.defaultProps = {
    projectDetail: {
        lineSelections: [],
        breedingMethodList: [],
        // Add other default fields as necessary
    },
    projectInfo: {
        cropId: 0,
        // Add other default fields as necessary
    },
};

// Define PropTypes for type checking
Plan.propTypes = {
    projectDetail: PropTypes.shape({
        id: PropTypes.number,
        projectNumber: PropTypes.string,
        description: PropTypes.string,
        plannedActivity: PropTypes.string,
        lineSelections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        breedingMethodList: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        numberOfPlannedLines: PropTypes.number,
        projectExtension: PropTypes.string,
        generationStart: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        generationEnd: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        descBreedingMethod: PropTypes.string,
        cultivationScope: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        cultivationScopeUnit: PropTypes.string,
        priority: PropTypes.number,
        ks_plan: PropTypes.number,
        planDeviation: PropTypes.string,
        // Define other expected fields
    }).isRequired,
    projectInfo: PropTypes.shape({
        cropId: PropTypes.number.isRequired,
        // Define other expected fields
    }).isRequired,
    isPlanEditable: PropTypes.bool.isRequired,
    isReportEditable: PropTypes.bool.isRequired,
    workFlowState: PropTypes.string.isRequired,
    reloadProject: PropTypes.func.isRequired,
    setEditingMaster: PropTypes.func.isRequired,
    breedingMethods: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        projectType: PropTypes.string,
    })).isRequired,
    currentUser: PropTypes.object, // Add more specific shape if possible
};

export default Plan;